<template>
	<div id="app">
		<nut-watermark class="mark1" v-if="flag" :width="watermarkWidth" z-index="1" :content="watermark"></nut-watermark>
		<transition :name="viewTransition" :mode="mode"><!-- 元素插入、更新或移除时添加动画效果,在这里放置要应用过渡效果的元素 -->
		<div class="routerView">
			<router-view v-slot="{ Component }">
					<keep-alive>
						<component v-if="$route.meta.keepAlive" :key="$route.name" :is="Component" />
					</keep-alive>
					<component v-if="!$route.meta.keepAlive" :key="$route.name" :is="Component" />
			</router-view>
		</div>
		</transition>
		<nut-tabbar class="index_tabbar" unactive-color="#7d7e80" active-color="#1989fa" @tab-switch="tabSwitch" :bottom="true" v-show="tabbarShow" v-model:visible="curr">
			<nut-tabbar-item v-for="(item,index) in tabList" :key="index" :tab-title="item.tabTitle" :img="item.icon" :activeImg="item.activeIcon"></nut-tabbar-item>
		</nut-tabbar>
	</div>
</template>

<script>
	import VueDisbounce from "vue-disbounce";
export default {
  name: 'app',
  data(){
    return {
      viewTransition:'fade',//淡入淡出效果
	  mode: 'out-in',
	  version: "1.0.0",
      selected: 'index',//???
      tabbarShow: false,
	  watermark: '',
	  flag: false,
	  watermarkWidth: 150,
      tabList:[
        {
          'tabTitle':'项目',
          'curr':false,
          'icon':require('./assets/imgs/item_icon.png'),
          'activeIcon':require('./assets/imgs/item_icon_on.png'),
        },
        {
          'tabTitle':'动态',
          'curr':false,
          'icon':require('./assets/imgs/dt_icon.png'),
          'activeIcon':require('./assets/imgs/dt_icon_on.png'),
        },
        {
          'tabTitle':'资料库',
          'curr':false,
          'icon':require('./assets/imgs/file_icon.png'),
          'activeIcon':require('./assets/imgs/file_icon_on.png'),
        },
        {
          'tabTitle':'我的',
          'curr':false,
          'icon':require('./assets/imgs/user_icon.png'),
          'activeIcon':require('./assets/imgs/user_icon_on.png'),
        },
      ],
      curr:0,//???
	  title: '首页'
    }
  },
  components: {
	  VueDisbounce
	},
  watch:{
    $route(to,from){
      //判断是否显示tabbar
	  if(to.query.reload){
		  let url = window.location.href.split('reload=true')[0]
		  if(url[url.length-1] == '&') url = url.slice(0,url.length-1)
		  window.location.href = url
		  location.reload()
	  }
	  this.tabShow(to.path)
    },
  },
  methods:{
    tabSwitch:function(value,index){
      this.curr = index
      if(this.curr == 0){
        this.$router.push('/')
      }else if(this.curr == 1){
        this.$router.push('/indexTrends')
      }else if(this.curr == 2){
        this.$router.push('/indexFile')
      }else if(this.curr == 3){
        this.$router.push('/user')
      }
    },
	tabEach(){
	  this.tabList.forEach(function(item){
	    item.curr = false
	  })
	},
	tabShow(path){
		this.selected = path;
		if(this.selected == 'index' || this.selected == '/'){
		  this.tabbarShow = true
		  this.curr = 0
		}else if(this.selected == 'indexTrends' || this.selected == '/indexTrends'){
		  this.tabbarShow = true
		  this.curr = 1
		}else if(this.selected == 'indexFile' || this.selected == '/indexFile'){
		  this.tabbarShow = true
		  this.curr = 2
		}else if(this.selected == 'user' ||this.selected ==  '/user'){
		  this.tabbarShow = true
		  this.curr = 3
		}else{
			this.tabbarShow = false
		}
		this.tabEach()
		this.tabList[this.curr].curr = true
	}
  },
  mounted() {
	  let user = JSON.parse(localStorage.getItem('user'))
	  if(user){
		  this.watermark = user.nickName+this.$common.getDateNow('yyyy-MM-dd')
		  this.watermarkWidth = this.watermark.length*10
		  this.flag = true
	  }else{
		  this.$router.push('/login')
	  }
		this.tabShow(this.$route.name)
		
		 //首先我们获得视口高度并将其乘以1%以获得1vh单位的值
		    let vh = window.innerHeight * 0.01
		    // 然后，我们将——vh自定义属性中的值设置为文档的根
		    document.documentElement.style.setProperty('--vh', `${vh}px`)
		 
		    // 我们监听resize事件 视图大小发生变化就重新计算1vh的值
		    window.addEventListener('resize', () => {
		      // 我们执行与前面相同的脚本
		      let vh = window.innerHeight * 0.01
		      console.log(vh);
		      document.documentElement.style.setProperty('--vh', `${vh}px`)
		    })
  },
  created() {
	 this.$axios.getAppWebLastVersion().then(res=>{
		 if(res.data.errorCode == '00000'){
			 if(res.data.data.appVersion != this.version){
				 location.reload()
			 }
		 }
	 })
  }
}
</script>

<style>
body{
	margin: 0;
	overflow: hidden;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	overscroll-behavior: none;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  position: relative;
}
.routerView{
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
}
.nut-tabbar{
	height: auto !important;
	padding: 10px 0;
}
.nut-tabbar-bottom{
	padding-bottom: 10px;
	padding-bottom: constant(safe-area-inset-bottom);
	padding-bottom: env(safe-area-inset-bottom);
}
.area-inset-top{
	padding-top: env(safe-area-inset-top);
	padding-top: constant(safe-area-inset-top);
}
.nut-price--normal{
	font-size: 14px !important;
}
.router-view{
	
}
</style>
