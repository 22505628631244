import {createRouter,createWebHashHistory} from "vue-router"


import index from '@/view/index'
import detail from '@/view/item/detail'
import detailXy from '@/view/item/detailXy'
import detailXyHis from '@/view/item/detailXyHis'
import people from '@/view/item/people'
import search from '@/view/item/search'
import itemLabel from '@/view/item/label'
import itemDesc from '@/view/item/desc'
import itemCW from '@/view/item/cw'
import itemAdd from '@/view/item/add'
import indexFile from '@/view/file/index'
import fileDetail from '@/view/file/detail'
import fileDetailXy from '@/view/file/detailXy'
import fileDetailList from '@/view/file/xyList'
import iframe from '@/view/file/iframe'
import indexTrends from '@/view/trends/index'
import user from '@/view/user/user'
import login from '@/view/user/login'
import loginNext from '@/view/user/loginNext'

const routes = [
	{
	  path: '/',
	  name: 'index',
	  component: index,
	  meta:{
        title: '首页',
		keepAlive: true,
		isBack: false,
		color: '#f7f7f7'
      }
	},
	{
	  path: '/indexFile',
	  name: 'indexFile',
	  component: indexFile,
	  meta:{
	    title: '资料',
		keepAlive: false,
		color: '#5484EA'
	  }
	},
	{
	  path: '/fileDetailXy',
	  name: 'fileDetailXy',
	  component: fileDetailXy,
	  meta:{
	    title: '协议库',
		keepAlive: false,
		color: '#5484EA'
	  }
	},
	{
	  path: '/fileDetailList',
	  name: 'fileDetailList',
	  component: fileDetailList,
	  meta:{
	    title: '协议库列表',
		keepAlive: false,
		color: '#5484EA'
	  }
	},
	{
	  path: '/indexTrends',
	  name: 'indexTrends',
	  component: indexTrends,
	  meta:{
	    title: '动态',
		keepAlive: false,
		color: '#f7f7f7'
	  }
	},
	{
	  path: '/detail',
	  name: 'detail',
	  component: detail,
	  meta:{
	    title: '详情',
		keepAlive: false,
		color: '#f7f7f7'
	  }
	},
	{
	  path: '/people',
	  name: 'people',
	  component: people,
	  meta:{
	    title: '项目中成员',
		keepAlive: false,
		color: '#f7f7f7'
	  }
	},
	{
	  path: '/search',
	  name: 'search',
	  component: search,
	  meta:{
	    title: '搜索',
		keepAlive: false,
		color: '#f7f7f7'
	  }
	},
	{
	  path: '/itemLabel',
	  name: 'itemLabel',
	  component: itemLabel,
	  meta:{
	    title: '项目标签',
		keepAlive: false,
		color: '#f7f7f7'
	  }
	},
	{
	  path: '/itemDesc',
	  name: 'itemDesc',
	  component: itemDesc,
	  meta:{
	    title: '添加备注',
		keepAlive: false,
		color: '#f7f7f7'
	  }
	},
	{
	  path: '/itemAdd',
	  name: 'itemAdd',
	  component: itemAdd,
	  meta:{
	    title: '添加项目',
		keepAlive: false,
		color: '#f7f7f7'
	  }
	},
	{
	  path: '/itemCW',
	  name: 'itemCW',
	  component: itemCW,
	  meta:{
	    title: '财务',
		keepAlive: false,
		color: '#f7f7f7'
	  }
	},
	{
	  path: '/fileDetail',
	  name: 'fileDetail',
	  component: fileDetail,
	  meta:{
	    title: '项目资料',
		keepAlive: false,
		color: '#f7f7f7'
	  }
	},
	{
	  path: '/iframe',
	  name: 'iframe',
	  component: iframe,
	  meta:{
	    title: '预览',
		keepAlive: false,
		color: '#f7f7f7'
	  }
	},
	{
	  path: '/detailXy',
	  name: 'detailXy',
	  component: detailXy,
	  meta:{
	    title: '详情',
		keepAlive: false,
		color: '#f7f7f7'
	  }
	},
	{
	  path: '/detailXyHis',
	  name: 'detailXyHis',
	  component: detailXyHis,
	  meta:{
	    title: '详情',
		keepAlive: false,
		color: '#f7f7f7'
	  }
	},
	{
	  path: '/user',
	  name: 'user',
	  component: user,
	  meta:{
	    title: '个人资料',
		keepAlive: false,
		color: '#5484EA'
	  }
	},
	{
	  path: '/login',
	  name: 'login',
	  component: login,
	  meta:{
	    title: '登录',
		keepAlive: false,
		color: '#5484EA'
	  }
	},
	{
	  path: '/loginNext',
	  name: 'loginNext',
	  component: loginNext,
	  meta:{
	    title: '登录',
		keepAlive: false,
		color: '#5484EA'
	  }
	},
]

export const router = createRouter({
	history: createWebHashHistory(),
	routes: routes
})


export default router
