import { createApp } from 'vue'
import App from './App.vue'
import NutUI from "@nutui/nutui";
import "@nutui/nutui/dist/style.css";
import router from './router'
import axios from '@/api/index'
import common from '@/components/common'
import VueHtml5EditorConfig from '@/components/editor'
import VueHtml5Editor from 'vue-html5-editor'
import './registerServiceWorker'

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
	document.querySelector('meta[name="theme-color"]').content = to.meta.color
	if(to.name == 'index' && (from.name == 'loginNext' || from.name == undefined)){
		to.meta.isBack = true
	}
	if(to.name == 'detail' && from.name == 'index'){
		to.meta.isBack = true
	}
	if(to.name == 'detail' && from.name == undefined){
		to.meta.isBack = true
		to.meta.goIndex = true
	}
  if(to.name == 'loginNext' && from.name == 'index'){
	  next(false)
  }else{
	  next()
  }
})

const app = createApp(App)

app.config.globalProperties.$axios = axios
app.config.globalProperties.$router = router
app.config.globalProperties.$common = common

app.use(VueHtml5Editor, VueHtml5EditorConfig)
app.use(router)
app.use(NutUI)
app.mount("#app");




