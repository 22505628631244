<template>
  <div class="file" id="file">
		<nut-navbar @on-click-back="event.back" fixed @on-click-right="event.right" :title="state.title+'-协议库'" style="background-color: transparent;" :safe-area-inset-top="true">
		    <template #left>
		      <nut-icon name="rect-left" color="#777"></nut-icon>
		    </template>
		</nut-navbar>
		<!-- <div id="file"> -->
			<nut-infiniteloading
				pull-txt="   "
				container-id="file"
				:use-window="false"
			  :is-open-refresh="true"
			  pull-icon="loading1"
			  @refresh="refresh"
			  >
				<zl-component :listData="state.list" @fileStatus="event.getStatus"></zl-component>
				<div style="margin: 10px auto;font-size: 14px;color: #a1a1a1;" v-if="state.listLs.length">-----以下是改协议的历史版本-----</div>
				<zl-component :listData="state.listLs" @fileStatus="event.getStatus"></zl-component>
			</nut-infiniteloading>
		<!-- </div> -->
		<file-p v-if="state.fileBox" :url="state.fileUrl" :type="state.fileType" @fileClose="event.getStatus"></file-p>
  </div>
</template>

<script>
	import { reactive, onMounted, getCurrentInstance, toRefs, ref} from 'vue';
	import zlComponent from '@/view/file/list'
	import fileP from '@/view/file/iframe'
	import { Toast } from '@nutui/nutui';
	export default {
		components:{
			zlComponent,
			fileP
		},
		setup() {
			const { proxy } = getCurrentInstance()
			const uploadFile = ref(null)
			const state = reactive ({
				tab1value: '0',
				cur:1,
				list:[],
				listLs:[],
				protocolId: '',
				protocolFileId: '',
				title:'',
				showPop: false,
				user: [],
				fileUrl: '',
				fileBox: false,
				fileType: '',
				file: require('@/assets/imgs/file.png')
			});
			const refresh = done => {
				state.cur = 1
				event.getList(state.type,done)
			}
			const loading = (msg)=>{
				Toast.loading(msg)
			} 
			onMounted(()=>{
				state.user = JSON.parse(localStorage.getItem('user'))
				state.protocolId = proxy.$route.query.id
				state.protocolFileId = proxy.$route.query.protocolFileId
				state.title = proxy.$route.query.name
				event.getList(state.type,function(){})
			})
			const event={
				getStatus(res){
					state.fileBox = res.fileBox
					state.fileUrl = res.fileUrl
					state.fileType = res.fileType
				},
				getList(type,fun){
					proxy.$axios.getProtocolByProtocolId({protocolId:state.protocolId}).then(res =>{
						if(res.data.errorCode == '00000'){
							if(res.data.data.protocolFileId){
								state.list = res.data.data
								proxy.$axios.getProtocolFileByProtocolId({protocolId:state.protocolId}).then(res =>{
									if(res.data.errorCode == '00000'){
										state.listLs = res.data.data
										fun()
									}
								})
							}else{
								Toast.warn('此协议暂无文件')
							}
						}
					})
				},
				back(){
					proxy.$router.go(-1)
				}
			}
			return { state,event,toRefs,refresh,uploadFile, loading };
		}
	};
</script>

<style>
	.file{
		background-color: #f7f7f7;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
	}
</style>