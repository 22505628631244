<template>
  <div class="file" id="file">
		<nut-navbar @on-click-back="event.back" fixed @on-click-right="event.right" :title="state.title+'-协议库'" style="background-color: transparent;" :safe-area-inset-top="true">
		    <template #left>
		      <nut-icon name="rect-left" color="#777"></nut-icon>
		    </template>
		</nut-navbar>
		<!-- <div id="file"> -->
			<nut-infiniteloading
				pull-txt="   "
				container-id="file"
				:use-window="false"
			  :is-open-refresh="true"
			  pull-icon="loading1"
			  @refresh="refresh"
			  >
				<div class="file_li" @click="event.xyFileDetail(item)" v-for="item in state.list">
					<span class="file_icon" :style="'background-image: url('+state.file+');'"></span>
					<div class="file_cont">
						<div class="file_name huanhang">{{item.protocolName}}</div>
					</div>
				</div>
			</nut-infiniteloading>
		<!-- </div> -->
		<file-p v-if="state.fileBox" :url="state.fileUrl" :type="state.fileType" @fileClose="event.getStatus"></file-p>
  </div>
</template>

<script>
	import { reactive, onMounted, getCurrentInstance, toRefs, ref} from 'vue';
	import zlComponent from '@/view/file/list'
	import fileP from '@/view/file/iframe'
	import { Toast } from '@nutui/nutui';
	import oss from 'ali-oss'
	export default {
		components:{
			zlComponent,
			fileP
		},
		setup() {
			const { proxy } = getCurrentInstance()
			const uploadFile = ref(null)
			const state = reactive ({
				tab1value: '0',
				cur:1,
				list:[],
				type:1,
				projectId: '',
				title:'',
				showPop: false,
				user: [],
				fileUrl: '',
				fileBox: false,
				fileType: '',
				file: require('@/assets/imgs/file.png')
			});
			const refresh = done => {
				state.cur = 1
				event.getList(state.type,done)
			}
			const loading = (msg)=>{
				Toast.loading(msg)
			} 
			onMounted(()=>{
				state.user = JSON.parse(localStorage.getItem('user'))
				state.projectId = proxy.$route.query.id
				state.title = proxy.$route.query.name
				event.getList(state.type,function(){})
			})
			const event={
				xyFileDetail(item){
					proxy.$router.push({name:'fileDetailXy',query:{id:item.protocolId,name:state.title,protocolFileId:item.protocolFileId}})
				},
				getStatus(res){
					state.fileBox = res.fileBox
					state.fileUrl = res.fileUrl
					state.fileType = res.fileType
				},
				getList(type,fun){
					proxy.$axios.getProtocolByProjectId({projectId:state.projectId,status:3}).then(res =>{
						if(res.data.errorCode == '00000'){
							state.list = res.data.data
							fun()
						}
					})
				},
				back(){
					proxy.$router.go(-1)
				},
			}
			return { state,event,toRefs,refresh,uploadFile, loading };
		}
	};
</script>

<style>
</style>