<template>
  <div class="file" id="file">
		<nut-navbar @on-click-back="event.back" fixed @on-click-right="event.right" :title="state.title" style="background-color: transparent;" :safe-area-inset-top="true">
		    <template #left>
		      <nut-icon name="rect-left" color="#777"></nut-icon>
		    </template>
		    <template #right>
				<div style="color: #666;">
					{{state.typeList[state.type-1]}}
					<span class="xiala_icon xiala_icon_hui"></span>
				</div>
		    </template>
		</nut-navbar>
		<!-- <div id="file"> -->
			<nut-infiniteloading
				pull-txt="   "
				container-id="file"
				:use-window="false"
			  :is-open-refresh="true"
			  pull-icon="loading1"
			  @refresh="refresh"
			  >
				<div class="file_li" @click="event.xyFileDetail">
					<span class="file_icon" :style="'background-image: url('+state.file+');'"></span>
					<div class="file_cont">
						<div class="file_name huanhang">协议库</div>
					</div>
				</div>
				<zl-component :listData="state.list" @fileStatus="event.getStatus"></zl-component>
			</nut-infiniteloading>
		<!-- </div> -->
		<div @click="event.upload" class="upload_btn"></div>
		<nut-popup pop-class="pop_cont" round v-model:visible="state.showPop">
			<div class="popup_li" v-for="(item,index) in state.typeList" @click="event.popClick(index,item)">
				{{item}}
			</div>
		</nut-popup>
		<input type="file" ref="uploadFile" @change="event.fileChange" style="width:0;height:0;opacity: 0;">
		<file-p v-if="state.fileBox" :url="state.fileUrl" :type="state.fileType" @fileClose="event.getStatus"></file-p>
  </div>
</template>

<script>
	import { reactive, onMounted, getCurrentInstance, toRefs, ref} from 'vue';
	import zlComponent from '@/view/file/list'
	import fileP from '@/view/file/iframe'
	import { Toast } from '@nutui/nutui';
	import oss from 'ali-oss'
	export default {
		components:{
			zlComponent,
			fileP
		},
		setup() {
			const { proxy } = getCurrentInstance()
			const uploadFile = ref(null)
			const state = reactive ({
				tab1value: '0',
				cur:1,
				list:[],
				typeList:['按时间','按名称','按类型'],
				type:1,
				projectId: '',
				title:'',
				showPop: false,
				user: [],
				fileUrl: '',
				fileBox: false,
				fileType: '',
				file: require('@/assets/imgs/file.png')
			});
			const refresh = done => {
				state.cur = 1
				event.getList(state.type,done)
			}
			const loading = (msg)=>{
				Toast.loading(msg)
			} 
			onMounted(()=>{
				state.user = JSON.parse(localStorage.getItem('user'))
				state.projectId = proxy.$route.query.id
				state.title = proxy.$route.query.name
				event.getList(state.type,function(){})
			})
			const event={
				xyFileDetail(){
					proxy.$router.push({name:'fileDetailList',query:{id:state.projectId,name:state.title}})
				},
				getStatus(res){
					state.fileBox = res.fileBox
					state.fileUrl = res.fileUrl
					state.fileType = res.fileType
				},
				getList(type,fun){
					proxy.$axios.getInformationDetail({projectId:state.projectId,type:type}).then(res =>{
						if(res.data.errorCode == '00000'){
							state.list = res.data.data
							fun()
						}
					})
				},
				back(){
					proxy.$router.push('/indexFile')
				},
				popClick(type,item){
					state.type = type+1
					event.getList(state.type,function(){})
					state.showPop = false
				},
				right(){
					state.showPop = true
				},
				upload(){
					uploadFile.value.click()
				},
				fileChange(file){
					loading('上传中...')
					event.getOssStsConfig(file.target.files[0])
				},
				getOssStsConfig(file){
					proxy.$axios.getOssStsConfig(state.user.username).then(res=>{
						if(res.data.errorCode == '00000'){
							state.oss = new oss({
							  // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
							  region: 'oss-cn-shanghai',
							  // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
							  accessKeyId: res.data.data.accessKeyId,
							  accessKeySecret: res.data.data.accessKeySecret,
							  // 从STS服务获取的安全令牌（SecurityToken）。
							  stsToken: res.data.data.securityToken,
							  // 填写Bucket名称。
							  bucket: res.data.data.bucketName
							});
							event.putObject(file)
						}
					})
				},
				putObject(data){
					let videoName = proxy.$common.getDateNow('yyyyMMdd')+'/'+state.user.userId+'_'+proxy.$common.getDateNow('yyyyMMddhhmmss')+'_'+data.name
					state.oss.put(
					  videoName,
					  data
					).then(result => {
					  console.log(result)
					  if(result.res.status == 200){
					    console.log(result)
						event.insertInformation(result,data)
					  }
					}).then(function (r2) {
					  console.log('get success: %j', r2);
					}).catch(function (err) {
					  console.error('error: %j', err);
					});
				},
				insertInformation(data,file){
					let type = file.name.split('.')
					console.log(type[type.length-1])
					proxy.$axios.insertInformation({userId:state.user.userId,projectDetailsId:state.projectId,fileName:file.name,fileType:type[type.length-1],fileUploadAddress:data.name}).then(res=>{
						if(res.data.errorCode == '00000'){
							Toast.success(res.data.errorMsg)
							event.getList(state.type,function(){})
						}
					})
				},
			}
			return { state,event,toRefs,refresh,uploadFile, loading };
		}
	};
</script>

<style>
	.input_box{
		border: 1px solid #fff;
		background-color: transparent;
		border-radius: 10px;
		height: 20px;
		line-height: 20px;
		padding: 5px 10px;
		font-size: 14px;
	}
	.input_box::placeholder{
		color: #eee;
	}
	.file{
		background-color: #f7f7f7;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
	}
	.xiala_icon_hui{
		border: 5px solid transparent;
		border-top-color: #666;
	}
	.upload_btn{
		position: fixed;
		bottom: 100px;
		right: 20px;
		background-image: url('../../assets/imgs/add_icon1.png');
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: top center;
		width: 56px;
		height: 56px;
	}
</style>